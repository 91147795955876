import React from 'react';
import { getAPIRoot } from '../../../helpers/fetch';
import PortfolioDetailTemplate from '../../../templates/portfolioDetail';
import NotFoundPage from '../../404';
import { trackNotFoundEvent } from '../../../utils/application-insights';

const PortfolioDetail = (props) => {
  const {
    serverData: { data },
  } = props;
  return data ? (
    <PortfolioDetailTemplate contributionData={data} />
  ) : (
    <NotFoundPage />
  );
};

export async function getServerData(ctx) {
  const id = ctx.params.id;
  const apiRoot = await getAPIRoot();
  const locale = ctx.pageContext.locale || 'en';
  const apiUrl = `${apiRoot}${process.env.GATSBY_API_PORTFOLIO_DETAIL}/${id}?language=${locale}`;
  let res = null;

  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error(
        `Fetching failed. Error: ${res.status} - ${res.statusText}`
      );
    }

    if (res.status === 204) {
      return {
        status: 301,
        headers: {
          Location: `/${locale}/brands-creatives/overview/`,
        },
        props: {},
      };
    }

    const data = await res.json();
    // TODO: check for correct slug from backend and redirect accordingly
    return {
      status: 200,
      props: { data },
    };
  } catch (error) {
    trackNotFoundEvent({
      type: 'Portfolio Detail',
      id,
      locale,
      apiRoot,
      apiUrl,
      apiStatusCode: res?.status,
    });

    return {
      status: 404,
      headers: {},
      props: {},
    };
  }
}

export default PortfolioDetail;
